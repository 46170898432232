import React, { FunctionComponent } from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { Gallery, PhotoType } from '../components/Gallery'

interface GalleryTemplateProps {
  data: {
    allWpMediaItem: {
      nodes: PhotoType[]
    }
  }
  pageContext: {
    id: number
    title: string
    photoTownLink: string
  }
}

const GalleryTemplate: FunctionComponent<GalleryTemplateProps> = (props) => {
  const photos = props.data.allWpMediaItem.nodes.filter(
    (el) => el.mimeType === 'image/jpeg'
  )
  photos.sort((a, b) => {
    const timestampA = parseInt(a.mediaDetails.meta.createdTimestamp, 10)
    const timestampB = parseInt(b.mediaDetails.meta.createdTimestamp, 10)
    if (timestampA === timestampB) {
      return 0
    }
    if (timestampA > timestampB) {
      return 1
    }
    return -1
  })
  const videos = props.data.allWpMediaItem.nodes.filter(
    (el) => el.mimeType === 'video/mp4'
  )
  videos.sort((a, b) => {
    if (a.databaseId === b.databaseId) {
      return 0
    }
    if (a.databaseId > b.databaseId) {
      return 1
    }
    return -1
  })
  return (
    <Layout>
      <Gallery
        photos={photos}
        videos={videos}
        title={props.pageContext.title}
        photoTownLink={props.pageContext.photoTownLink}
      />
    </Layout>
  )
}

export default GalleryTemplate

export const query = graphql`
  query($id: Int) {
    allWpMediaItem(
      filter: {
        parentDatabaseId: { eq: $id }
        mimeType: { in: ["image/jpeg", "video/mp4"] }
      }
    ) {
      nodes {
        databaseId
        caption
        mimeType
        localFile {
          extension
        }
        mediaDetails {
          meta {
            createdTimestamp
          }
          height
          width
        }
        mediaItemUrl
        thumbnail: localFile {
          childImageSharp {
            gatsbyImageData(
              width: 300
              placeholder: BLURRED
              layout: CONSTRAINED
            )
          }
        }
        fullSize: localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
