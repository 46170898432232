import React, { useContext, useEffect, FunctionComponent } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import SEO from './SEO'
import * as galleryStyles from './Gallery.module.css'
import { GlobalContext, ContextType } from './GlobalContextProvider'
// import Photo from './Photo'
import Nav, { updateNav } from './Nav'

export interface PhotoType {
  databaseId: number
  post: number
  caption: string
  mimeType: string
  localFile: {
    extension: string
  }
  mediaDetails: {
    meta: {
      createdTimestamp: string
    }
    width: number
    height: number
  }
  mediaItemUrl: string
  thumbnail: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  fullSize: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

interface GalleryProps {
  photos: PhotoType[]
  videos: PhotoType[]
  title: string
  photoTownLink: string
}

export const Gallery: FunctionComponent<GalleryProps> = (props) => {
  updateNav()
  const { nav, setNav } = useContext(GlobalContext) as ContextType
  return (
    <main className={galleryStyles.galleryContainer}>
      <div className={galleryStyles.header}>{props.title}</div>
      <div className={galleryStyles.gallery}>
        {props.videos.map((video) => (
          <div
            key={video.databaseId}
            className={`${galleryStyles.landscape} ${galleryStyles.galleryPic}`}
          >
            <video controls width="300">
              <source src={video.mediaItemUrl}></source>
            </video>
            <div
              className={galleryStyles.caption}
              dangerouslySetInnerHTML={{ __html: video.caption }}
            />
          </div>
        ))}
        {props.photos.map((photo) => {
          let galleryClass =
            photo.thumbnail.childImageSharp.gatsbyImageData.height <
            photo.thumbnail.childImageSharp.gatsbyImageData.width
              ? `${galleryStyles.landscape}`
              : `${galleryStyles.portrait}`
          galleryClass += ` ${galleryStyles.galleryPic}`
          return (
            <div key={photo.databaseId} className={galleryClass}>
              <Link to={`${props.photoTownLink}/?id=${photo.databaseId}`}>
                <GatsbyImage
                  image={photo.thumbnail.childImageSharp.gatsbyImageData}
                  alt={photo.caption}
                />
                <div
                  className={galleryStyles.caption}
                  dangerouslySetInnerHTML={{ __html: photo.caption }}
                />
              </Link>
            </div>
          )
        })}
      </div>
      <Nav />
      <SEO
        title={`Gallery - ${props.title}`}
        description={`Photos from our tour visiting ${props.title}`}
        image="/bertie-beach-small.jpg"
        type="article"
      />
    </main>
  )
}
